.WebLogo {
  margin-top: .5rem;
  height: 4rem;
  width: 4rem;
  transition: 1s ease-in-out;
  cursor: pointer
}

.WebLogo:hover {
  transition: 1s;
  transform: rotate(360deg);
}

@keyframes rotate360deg {
  from{
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}