.Mosaique {
    height: 400;
    width: 400;
}

.MosaiqueAvatar {
    filter: invert(1);
}

.MosaiqueDot
{
    position: absolute;
    bottom: -25px;

    display: block;

    width: 100%;
    padding: 0;
    margin: 0;

    list-style: none;

    text-align: center;
}
.MosaiqueDot li
{
    position: relative;

    display: inline-block;

    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;

    cursor: pointer;
}
.MosaiqueDot li button
{
    font-size: 0;
    line-height: 0;

    display: block;

    width: 20px;
    height: 20px;
    padding: 5px;

    cursor: pointer;

    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}
.MosaiqueDot li button:hover,
.MosaiqueDot li button:focus
{
    outline: none;
}
.MosaiqueDot li button:hover:before,
.MosaiqueDot li button:focus:before
{
    opacity: 1;
}
.MosaiqueDot li button:before
{
    font-family: 'slick';
    font-size: 10px;
    line-height: 20px;

    position: absolute;
    top: 0;
    left: 0;

    width: 20px;
    height: 20px;

    content: '•';
    text-align: center;

    opacity: .25;
    color: black;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.MosaiqueDot li.slick-active button:before
{
    opacity: .75;
    color: black;
}