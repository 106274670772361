.projectCard {
    position: relative;

    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 5px 10px 1px #878787;
    margin: 20px;
    padding: 30px;
    height: 100%;
    min-height: 250px;
}
